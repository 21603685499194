<template>
  <ImageStrapiImage
    v-if="data.bananaImage?.data"
    class="z-index-10 absolute w-36"
    :class="bananaPosition === BananaPositionEnum.BOTTOM_RIGHT ? '-bottom-10 -right-5' : '-bottom-6 -left-3'"
    :data="data.bananaImage.data" />
</template>

<script setup lang="ts">
import type { SimpleImage } from "~/types/strapi/components/layout-sections/interfaces/SimpleImage";
import BananaPositionEnum from "~/utils/enum/BananaPosition";

const props = defineProps<{
  data: SimpleImage;
}>();

const bananaPosition = computed(() => props.data.bananaPosition ?? BananaPositionEnum.BOTTOM_RIGHT);
</script>

<style scoped></style>
